 const  setAddImageList = (target) => {
    let imageList = new Array();
    if(target.img) imageList.push(target.img)
    if(target.img2) imageList.push(target.img2)
    if(target.img3) imageList.push(target.img3)
    if(target.img4) imageList.push(target.img4)
    if(target.img5) imageList.push(target.img5)
    if(target.img6) imageList.push(target.img6)
    return imageList
  }
export default {
  // 메인 배너
  BANNER(state, banner) {
    state.banner = banner;
  },
  // 메인 검색
  TOTAL_SEARCH(state, totalsearch) {
    state.totalsearch = totalsearch;
  },
  MAIN_STATICS(state, mainstatics) {
    state.mainstatics = mainstatics;
  },
  MAIN_NOTICE(state, mainnotice) {
    state.mainnotice = mainnotice;
  },

  // 공지
  BOARD_NOTICE(state, notice) {
    let item = notice.data.currentlist.pageinfo;
    let itemNo = item.totalcount - (item.currentpage - 1) * 10;
    state.itemNo = itemNo;
    let period = notice.data.currentlist.noticelist;
    period.forEach(el => {
      el.regdate = el.regdate.toString().substr(0, 10);
    });
    state.notice = notice;
  },
  INFO_NOTICEINFO(state, noticeinfo) {
    state.noticeinfo = noticeinfo;
  },
  // 관광정보
  BOARD_TOURLIST(state, tourlist) {
    state.tourlist = tourlist;
  },
  INFO_TOURINFO(state, tourinfo) {
    tourinfo.data.image = setAddImageList(tourinfo?.data)
    state.tourinfo = tourinfo;
  },
 
  FOR_INFO(state, forInfo) {
    forInfo.data.image = setAddImageList(forInfo?.data)
    state.forInfo = forInfo;
  },
  // 의료기관
  BOARD_MEDICALLIST(state, medicalList) {
    state.medicalList = medicalList;
  },
  // 외국인 유치
  BOARD_FORLIST(state, forinfolist) {
    state.forinfolist = forinfolist;
  },
  
  INFO_MEDICALINFO(state, medicalinfo) {
    let info = medicalinfo?.data
    medicalinfo.data.image = setAddImageList(info)
    state.medicalinfo = medicalinfo;
  },
  // 진구 가맹점 리스트
  BOARD_JINGULIST(state, jinguFranchiseList) {
    state.jinguFranchiseList = jinguFranchiseList;
  },
  INFO_JINGUINFO(state, jinguFranchiseInfo) {
    let info = jinguFranchiseInfo?.data
    jinguFranchiseInfo.data.image = setAddImageList(info)
    state.jinguFranchiseInfo = jinguFranchiseInfo;
  },
  MAP_tourlist(state, tourlist) {
    state.tourlist = tourlist;
  },

  // FAQ
  BOARD_FAQLIST(state, faqList) {
    state.faqList = faqList;
  },
  MAP_CATLIST(state, categoryinfo) {
    if (state.categorykindof == 1) {
      categoryinfo.data.categorylist.reverse();
    }
    state.categoryinfo = categoryinfo;
  },

  // eBook
  BOARD_EBOOK(state, eBook) {
    let item = eBook.data.currentlist.pageinfo;
    let itemNo = item.totalcount - (item.currentpage - 1) * 10;
    state.itemNo = itemNo;
    let period = eBook.data.currentlist.eventlist;
    period.forEach(el => {
      el.regdate = el.regdate.toString().substr(0, 10);
    });
    state.eBook = eBook;
  },
  INFO_EBOOKINFO(state, eBookinfo) {
    state.eBookinfo = eBookinfo;
  },

  // 번역
  PAPAGO_DETECTLANG(state, detectlang) {
    state.detectlang = detectlang;
  },
  PAPAGO_TRANSLATE(state, translate) {
    state.translate = translate;
  },

  // 항공운행
  TRAFFIC_AIRINFO(state, airinfo) {
    state.airinfo = airinfo;
    let period = airinfo.data.items?.item;
    let airArray = new Array();
    //값이 하나인경우 배열로 만들기
    if (period && !period.length) {
      airArray.push(period);
      period = airArray;
    }
    period?.forEach(el => {
      if (el?.internationalTime) {
        el.internationalTime = el.internationalTime.toString().substr(0, 2) + ':' + el.internationalTime.toString().substr(2, 2);
      }
      if (el?.domesticStartTime) {
        el.domesticStartTime = el.domesticStartTime.toString().substr(0, 2) + ':' + el.domesticStartTime.toString().substr(2, 2);
      }
      if (el?.domesticArrivalTime) {
        el.domesticArrivalTime = el.domesticArrivalTime.toString().substr(0, 2) + ':' + el.domesticArrivalTime.toString().substr(2, 2);
      }
      
    })
    if (period) {
      airinfo.data.items.item = period;
    }

    state.airinfo = airinfo;
  },
  TRAFFIC_AIRSEARCH(state, airsearch) {
    state.airsearch = airsearch;
  },
  TRAFFIC_AIRSEARCHARR(state, airsearcharr) {
    state.airsearcharr = airsearcharr;
  },

  // 기차운행
  // 운행일정 검색
  TRAFFIC_TRAININFO(state, traininfo) {
    state.traininfo = traininfo;
    let period = traininfo.data.items.item;
    period.forEach(el => {
      el.arrplandtime = el.arrplandtime.toString().substr(8, 2) + ':' + el.arrplandtime.toString().substr(10, 2);
      el.depplandtime = el.depplandtime.toString().substr(8, 2) + ':' + el.depplandtime.toString().substr(10, 2);
    });
    state.traininfo = traininfo;
  },
  // 출발역 선택
  TRAFFIC_TRAINLISTDEP(state, trainlistdep) {
    state.trainlistdep = trainlistdep;
  },
  // 도착역 선택
  TRAFFIC_TRAINLISTARR(state, trainlistarr) {
    state.trainlistarr = trainlistarr;
  },
  // ktx,srt역 목록
  TRAFFIC_TRAINLIST(state, trainlist) {
    state.trainlist = trainlist;
  },

  // ai 추천코스 카테고리 조회
  AI_CATEGORYLIST(state, aiCategorylist) {
    state.aiCategorylist = aiCategorylist;
  },
  // ai 추천코스 list
  AI_COURSELIST(state, aiCourselist) {
    state.aiCourselist = aiCourselist;
  },
  // ai 추천코스 상세보기
  AI_COURSEINFO(state, aiCourseinfo) {
    state.aiCourseinfo = aiCourseinfo;
  },
};
