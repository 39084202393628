// 퍼블 추가 : 커스텀 셀렉트박스
function jg_customSelect() {
  // 옵션 보이기/숨기기
  document.addEventListener('click', e => {
    let $eTarget = e.target;
    if ($eTarget.closest('.jg_common_selected')) {
      $eTarget = $eTarget.closest('.jg_common_selected');
      $eTarget.parentElement.querySelectorAll('.jg_common_select_list').forEach(el => {
        el.classList.toggle('active');
      });
    } else {
      document.querySelectorAll('.jg_common_select_list').forEach(el => {
        el.classList.remove('active');
      });
    }
  });
  // 선택된 항목 변경
  document.addEventListener('change', e => {
    let $eTarget = e.target;
    if ($eTarget.closest('.jg_common_selectbox input')) {
      $eTarget = $eTarget.closest('.jg_common_selectbox input');
      let selectedVal = $eTarget.closest('.jg_common_select_item').querySelector('span').innerText;

      $eTarget.closest('.jg_common_select_list').classList.remove('active');
      $eTarget
        .closest('.jg_common_select_list')
        .parentElement.querySelectorAll('.jg_common_selected')
        .forEach(el => {
          el.querySelector('span').innerText = selectedVal;
        });
    }
  });
}
jg_customSelect();

// 퍼블 추가 : 커스텀 셀렉트박스 (AI 통번역)
function aiTranslation() {
  // 옵션 보이기/숨기기
  document.addEventListener('click', e => {
    let $eTarget = e.target;
    if ($eTarget.closest('.jg_ai_tran_selected')) {
      $eTarget = $eTarget.closest('.jg_ai_tran_selected');
      $eTarget.parentElement.querySelectorAll('.jg_ai_tran_select_list').forEach(el => {
        el.classList.toggle('active');
      });
    }
  });
  // 선택된 항목 변경
  document.addEventListener('change', e => {
    let $eTarget = e.target;
    if ($eTarget.closest('.jg_ai_tran_selectbox input')) {
      $eTarget = $eTarget.closest('.jg_ai_tran_selectbox input');
      let selectedVal = $eTarget.closest('.jg_ai_tran_select_item').querySelector('span').innerText;
      console.log(selectedVal);
      $eTarget.closest('.jg_ai_tran_select_list').classList.remove('active');
      $eTarget
        .closest('.jg_ai_tran_select_list')
        .parentElement.querySelectorAll('.jg_ai_tran_selected')
        .forEach(el => {
          el.querySelector('span').innerText = selectedVal;
        });
    }
  });

  // textarea 입력 시 x 버튼 보이기/숨기기
  document.addEventListener('input', e => {
    let $eTarget = e.target;
    if ($eTarget.closest('.jg_ai_tran_body textarea')) {
      $eTarget = $eTarget.closest('.jg_ai_tran_body textarea');
      const $resetBtn = $eTarget.parentElement.querySelector('.jg_ai_tran_reset');

      if ($eTarget.value.length > 0) {
        if (!$resetBtn.classList.contains('active')) {
          $resetBtn.classList.add('active');
        }
      } else {
        if ($resetBtn.classList.contains('active')) {
          $resetBtn.classList.remove('active');
        }
      }
    }
  });
  // x 버튼 클릭 시 textarea 내용 리셋
  document.addEventListener('click', e => {
    let $eTarget = e.target;
    if ($eTarget.closest('.jg_ai_tran_reset')) {
      $eTarget = $eTarget.closest('.jg_ai_tran_reset');
      $eTarget.parentElement.querySelector('textarea').value = '';
      $eTarget.classList.remove('active');
    }
  });
}
aiTranslation();

// FAQ 열기/닫기
function jg_faq() {
  document.addEventListener('click', e => {
    let $eTarget = e.target;

    if ($eTarget.closest('.jg_faq_question ')) {
      $eTarget = $eTarget.closest('.jg_faq_question ');
      $eTarget.classList.toggle('active');
    }
  });
}
jg_faq();

function pg_nav_select() {}
pg_nav_select();

/* 항공 자동완성기능 hover */
function searchHover() {
  document.addEventListener('mouseover', e => {
    let $eTarget = e.target;
    if ($eTarget.closest('.jg_air_search_comp li ')) {
      $eTarget = $eTarget.closest('.jg_air_search_comp li ');
      $eTarget.classList.add('searchHover');
      getSiblings($eTarget).forEach(el => {
        el.classList.remove('searchHover');
      });
    }
  });
}
function getSiblings(elem) {
  return Array.from(elem.parentNode.children).filter(function(sibling) {
    return sibling !== elem;
  });
}
searchHover();
