<template>
  <footer id="footer">
    <div class="wrap">
      <ul class="f_etc_list">
        <li>
          <a class="point_yellow" @click="$router.push('/copyright')">{{ $t('footer.h0') }}</a>
        </li>  
        <li>
          <a @click="$router.push('/site_map')">{{ $t('footer.h3') }}</a>
        </li>
        <!-- <li><a >오시는길</a></li> -->
      </ul>
      <div class="f_etc">
        <figure class="jingu_logo">
          <img src="../assets/images/jingu_logo.png" alt="" />
        </figure>
        <div class="f_etc_etc">
          <address>
            <span>{{ $t('footer.add') }}</span
            ><span>{{ $t('footer.tell') }}</span
            ><span>{{ $t('footer.fax') }}</span
            > 
          </address>
          <p class="copyright">
            Copyright(C) Busanjin-gu office All Right Reserved.
          </p>
        </div>
      </div>
      <div class="slt_box up family">
        <a class="btn">{{ $t('footer.selectBx') }}</a>
        <ul>
          <li>
            <a href="https://www.busanjin.go.kr/index.busanjin" target="_blank">{{ $t('footer.selectBx0') }}</a>
          </li>
        </ul>
      </div>
    </div>
  </footer>
</template>

<script>
export default {};
</script>

<style></style>
