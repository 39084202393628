// JS 수정본

// RESIZE
function jg_resize() {
  let winWidth = window.innerWidth;
  let winHeight = window.innerHeight;

  window.addEventListener(
    'resize',
    function() {
      winWidth;
      winHeight;
    },
    true,
  );
}
jg_resize();

// GNB & SITEMAP
function jg_gnb() {
  function sitemap(e1, e2) {
    if (e1.classList.contains('on')) {
      e2.classList.add('active');
    } else {
      e2.classList.remove('active');
    }
  }
  document.addEventListener('mouseover', function(e) {
    const $header = document.querySelector('#header');
    const $sitemap = document.querySelector('#sitemap');

    if (e.target.closest('.nav_container')) {
      if (!$header.classList.contains('on')) {
        $header.classList.add('on');
      }
      sitemap($header, $sitemap);
    }
  });
  document.addEventListener('mouseout', function(e) {
    const $header = document.querySelector('#header');
    const $sitemap = document.querySelector('#sitemap');

    if (e.target.closest('#header')) {
      if ($header.classList.contains('on')) {
        $header.classList.remove('on');
        document.querySelectorAll('#gnb li').forEach(el => {
          el.classList.remove('on');
        });
        sitemap($header, $sitemap);
      }
    }
  });
}
jg_gnb();

// HEADER SCROLL
function jg_winScoll() {
  window.addEventListener('scroll', function() {
    const content = document.querySelector('.nav_container');

    if (window.scrollY > 0) {
      content.classList.add('navmove');
    } else {
      content.classList.remove('navmove');
    }
  });
}
jg_winScoll();

// BTN TOP
function jg_btnTop() {
  document.addEventListener('click', e => {
    if (e.target.closest('.btn_top')) {
      e.preventDefault();
      window.scrollTo(0, 0);
      document.querySelector('#content').focus();
    }
  });
}
jg_btnTop();

// 글자수 제한
function jg_txtCheck() {
  function jg_txtCheckInit(el) {
    const $txtarea = el.closest('.txtarea');
    const txtMax = parseInt($txtarea.querySelector('.txt_max').innerText);
    const txtVal = el.value;
    const txtLength = txtVal.length;

    $txtarea.querySelector('.txt_length').innerText = txtLength;
    const $parent = el.parentElement;
    if (txtLength > txtMax) {
      $parent.classList
        .add('warning')
        .closest('[class*="dl_item"]')
        .classList.add('warning');
    } else {
      $parent.classList
        .remove('warning')
        .closest('[class*="dl_item"]')
        .classList.remove('warning');
    }
  }

  const $textarea = document.querySelectorAll('.txtarea textarea');
  if ($textarea.length > 0) {
    $textarea.forEach(el => {
      jg_txtCheckInit(el);
    });
  }

  document.addEventListener('input', e => {
    if (e.target.closest('.txtarea textarea')) {
      const $eTarget = e.target.closest('.txtarea textarea');
      jg_txtCheckInit($eTarget);
    }
  });
}
jg_txtCheck();

// 셀렉트박스
function jg_sltBox() {
  let hasClass = false;
  document.addEventListener('click', e => {
    let $eTarget = e.target;

    if ($eTarget.closest('.slt_box > a')) {
      $eTarget = $eTarget.closest('.slt_box > a');
      if (hasClass === false) {
        $eTarget.parentElement.classList.add('on');
        hasClass = true;
      } else {
        $eTarget.parentElement.classList.remove('on');
        hasClass = false;
      }
    }
    if (!$eTarget.classList.contains('btn')) {
      document.querySelectorAll('.slt_box').forEach(el => {
        el.classList.remove('on');
      });
    }
    if ($eTarget.closest('.slt_box.change li > a')) {
      $eTarget = $eTarget.closest('.slt_box.change li > a');
      const txt = $eTarget.innerText;
      $eTarget.closest('.slt_box').querySelector(':scope > a').innerText = txt;
    }
  });
}
jg_sltBox();
